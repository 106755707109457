<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>{{ report.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color2 color2Text--text"
        fab small
        @click.stop="openDrawer"
      >
        <v-icon>fas fa-ellipsis-v</v-icon>
      </v-btn>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text>
      <v-data-table
        :headers="heads"
        :items="reportData"
        class="elevation-1"
        :options.sync="pagination"
        :search="search"
      >
        <template
          v-if="loading"
          v-slot:no-data
        >
          <v-progress-circular indeterminate color="color3"></v-progress-circular>
        </template>

      </v-data-table>
    </v-card-text>

    <display-settings-drawer
      ref="drawer"
      :report="report"
      :headers="headers"
      @refresh-click="loadData"
      @download-click="downloadReport"
    ></display-settings-drawer>

  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import Papa from 'papaparse'
import moment from 'moment'
const DisplaySettingsDrawer = () => import('./DisplaySettingsDrawer.vue')

export default {
  props: ['username', 'report', 'active'],
  data () {
    return {
      reportData: [],
      loading: false,
      headers: [],
      search: null,
      pagination: {}
    }
  },
  computed: {
    heads () {
      return this.headers.filter(f => f.show)
    }
  },
  methods: {
    loadData () {
      this.closeDrawer()
      this.loading = true
      this.$VBL.reports.get(this.username, this.report.id)
        .then(r => {
          this.reportData = JSON.parse(r.data)
          this.reportData = this.reportData.sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
          this.processData()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    processData () {
      this.headers = []
      const row = this.reportData[0]
      const d = this.report.defaultColumns
      const hasDefaults = d && d.length > 0
      for (const key in row) {
        this.headers.push({
          text: key.replaceAll('_', ' '),
          value: key,
          show: hasDefaults ? d.includes(key) : true
        })
      }
      this.openDrawer()
    },
    openDrawer () {
      this.$refs.drawer.open()
    },
    closeDrawer () {
      this.$refs.drawer && this.$refs.drawer.cancel()
    },
    createFileForDownload (data, name) {
      var opt = { header: true }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    downloadReport () {
      const today = moment().format('L').replaceAll('/', '_')
      var fileName = `${this.report.name.replace(/ /g, '_')}${today}.csv`
      var data = this.reportData
      this.createFileForDownload(data, fileName)
    }
  },
  components: {
    DisplaySettingsDrawer
  },
  mounted () {
    this.loadData()
  }
}
</script>
